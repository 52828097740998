  import Avatar from 'components/base/Avatar';
  import { useState } from 'react';
  import { Button, Card, Dropdown, Form, Modal, Nav } from 'react-bootstrap';
  import defaultAvatar from 'assets/img/team/avatar-placeholder.webp';
  import FeatherIcon from 'feather-icons-react';
  import { Link } from 'react-router-dom';
  import Scrollbar from 'components/base/Scrollbar';
  import classNames from 'classnames';
  import { useNavigate } from 'react-router-dom';
  import { useUserContext } from 'layouts/UserContext';

  const ProfileDropdownMenu = ({ className }: { className?: string }) => {
    const {
      user,
      isLoading,
      error
    } = useUserContext();

    if (isLoading || !user) return null; // Not render due to null user / bad token

    const [navItems] = useState([
      {
        label: 'Profile',
        icon: 'user',
        active: true,
        link: '/hazbot/profile'
      },
      {
        label: 'Account ',
        icon: 'sliders'
      },
      {
        label: 'Support ',
        icon: 'help-circle',
        active: true,
        link: '/hazbot/faq'
      },
      {
        label: 'Language',
        icon: 'globe'
      } 
    ]);

    const navigate = useNavigate();

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successModalMessage, setSuccessModalMessage] = useState('');

    const handleEndSessionClick = async (e: any) => {
      e.preventDefault();

      localStorage.removeItem("hatateToken");
    
      setSuccessModalMessage('Sesión terminada')
      setShowSuccessModal(true);
    };

    const handleCloseSuccessModal = () => {
      setShowSuccessModal(false);
      setSuccessModalMessage('');

      navigate("/pages/authentication/split/Sign-in");
    };

    return (
      <>
      <Dropdown.Menu
        align="end"
        className={classNames(
          className,
          'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border'
        )}
      >
        <Card className="position-relative border-0">
          <Card.Header className="p-0">
            <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
              <Avatar src={defaultAvatar} size="xl" />
              <h6 className="text-body-emphasis">{ user.name + " " + user.lastName }</h6>
            </div></Card.Header>
            {/*}
            <div className="mb-3 mx-3">
              <Form.Control
                type="text"
                placeholder="Update your status"
                size="sm"
              />
            </div>
            */}<Card.Body>
            <div style={{ height: '10rem' }}>
              <Scrollbar>
                <Nav className="nav flex-column mb-2 pb-1">
                  {navItems.map(item => (
                    <Nav.Item key={item.label}>
                      <Nav.Link href={item.link} className="px-3">
                        <FeatherIcon
                          icon={item.icon}
                          size={16}
                          className="me-2 text-body"
                        />
                        <span className="text-body-highlight">{item.label}</span>
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Scrollbar>
            </div>
          </Card.Body>
          <Card.Footer className="p-0 border-top border-translucent">
            {/*
            <Nav className="nav flex-column my-3">
              <Nav.Item>
                <Nav.Link href="#!" className="px-3">
                  <FeatherIcon
                    icon="user-plus"
                    size={16}
                    className="me-2 text-body"
                  />
                  <span>Add another account</span>
                </Nav.Link>
              </Nav.Item>
            </Nav> 
            <hr /> 
            
            <br />
            */}
            <div className="px-3">
              <Button className="btn btn-phoenix-secondary d-flex flex-center w-100" onClick={ handleEndSessionClick }>
                <FeatherIcon icon="log-out" className="me-2" size={16} />
                Sign Out
              </Button>
            </div>
            <div className="my-2 text-center fw-bold fs-10 text-body-quaternary">
              <Link className="text-body-quaternary me-1" to="#!">
                Privacy policy
              </Link>
              •
              <Link className="text-body-quaternary mx-1" to="#!">
                Terms
              </Link>
              •
              <Link className="text-body-quaternary ms-1" to="#!">
                Cookies
              </Link>
            </div>
          </Card.Footer>
        </Card>
      </Dropdown.Menu>
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          {successModalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSuccessModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      </>
    );
  };

  export default ProfileDropdownMenu;
