import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
// import Footer from 'components/footers/Footer';
import NavbarDual from 'components/navbars/navbar-dual/NavbarDual';
import NavbarTopHorizontal from 'components/navbars/navbar-horizontal/NavbarTopHorizontal';
import NavbarTopDefault from 'components/navbars/navbar-top/NavbarTopDefault';
import NavbarVertical from 'components/navbars/navbar-vertical/NavbarVertical';
import { IUser } from 'data/models/user/IUser';
import { UserService } from 'helpers/userService';
import { useAppContext } from 'providers/AppProvider';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import { UserContext } from './UserContext';

const MainLayout: React.FC = () => {
  const {
    config: { navbarPosition }
  } = useAppContext();
  const { contentClass, footerClass } = useMainLayoutContext();
  const [user, setUser] = useState<IUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchUser = useCallback(async () => {
    try {
      const fetchedUser = await UserService.getUser();
      setUser(fetchedUser);
    } catch (err) {
      setError('Failed to fetch user data');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return (
    <UserContext.Provider value={{ user, isLoading, error, refetchUser: fetchUser }}>
      <Container fluid className="px-0">
        {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
          <NavbarVertical />
        )}
        {navbarPosition === 'vertical' && <NavbarTopDefault />}
        {(navbarPosition === 'horizontal' || navbarPosition === 'combo') && (
          <NavbarTopHorizontal />
        )}
        {navbarPosition === 'dual' && <NavbarDual />}
        <div className={classNames(contentClass, 'content')}>
          <Outlet />
       {/*}   <Footer className={classNames(footerClass, 'position-absolute')} /> */}
          {/*<ChatWidget />*/}
        </div>
      </Container>
    </UserContext.Provider>
  );
};

export default MainLayout;