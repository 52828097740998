import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faBuilding, faLock } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/base/Button';
import { Col, Form, Row, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiUrl, getUserIp } from "helpers/utils";
import { IconProp } from '@fortawesome/fontawesome-svg-core';

// Add this CSS to your stylesheet or as a styled-component
const formIconStyles = `
  .form-icon-container {
    position: relative;
  }
  .form-icon-input {
    padding-left: 35px; /* Adjust this value as needed */
  }
  .form-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-1%);
    color: #adb5bd;
  }
`;

const SignUpForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    lastName: '',
    company: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [termsChecked, setTermsChecked] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsChecked(e.target.checked);
  };

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if (isLoading) return; // Prevent multiple submissions
    
    setIsLoading(true);

    if (formData.password !== formData.confirmPassword) {
      setModalMessage('Passwords do not match');
      setShowErrorModal(true);
      setIsLoading(false);
      return;
    }

    if (!termsChecked) {
      setModalMessage('You must accept the terms and conditions');
      setShowErrorModal(true);
      setIsLoading(false);
      return;
    }

    let address;
    try {
      address = await getUserIp();
      if (!address) {
        throw new Error('Could not get your IP address for registration.');
      }
    } catch (error) {
      setModalMessage('Could not get your IP address for registration.');
      setShowErrorModal(true);
      setIsLoading(false);
      return;
    }

    const requestBody = {
      name: formData.name,
      lastName: formData.lastName,
      company: formData.company,
      email: formData.email,
      password: formData.password,
      address: address
    };

    try {
      const response = await axios.post(
        `${apiUrl}authentication/register`,
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }
      );

      // Handle the session token
      if (response.data.token) {
        // cookie created by Hatate
        console.log(response.data.token);
        localStorage.setItem("hatateToken", response.data.token);

        setModalMessage('Registration successful.');
        setShowSuccessModal(true);
      }
    } catch (error: any) {
      console.error(error);
      if (!error.response) {
        setModalMessage('No response received from Hatate backend');
      } else {
        setModalMessage(error.response.data.message);
      }
      setShowErrorModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    setShowErrorModal(false);
    if (showSuccessModal) {
      navigate('/');
    }
  };

  return (
    <>
      <style>{formIconStyles}</style>
      <div className="text-center mb-1">
        <h3 className="text-body-highlight mb-2">Sign Up</h3>
        <p className="text-body-tertiary">Create your account today</p>
      </div>
      <Form onSubmit={handleSignUp}>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="name">Name</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="name"
              type="text"
              placeholder="Name"
              value={formData.name}
              onChange={handleInputChange}
              required
              className="form-icon-input"
            />
            <FontAwesomeIcon icon={faUser as IconProp} className="form-icon" />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="lastName">Last Name</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="lastName"
              type="text"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleInputChange}
              required
              className="form-icon-input"
            />
            <FontAwesomeIcon icon={faUser as IconProp} className="form-icon" />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="company">Company</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="company"
              type="text"
              placeholder="Company"
              value={formData.company}
              onChange={handleInputChange}
              required
              className="form-icon-input"
            />
            <FontAwesomeIcon icon={faBuilding as IconProp} className="form-icon" />
          </div>
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Email address</Form.Label>
          <div className="form-icon-container">
            <Form.Control
              id="email"
              type="email"
              placeholder="name@example.com"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="form-icon-input"
            />
            <FontAwesomeIcon icon={faEnvelope as IconProp} className="form-icon" />
          </div>
        </Form.Group>
        <Row className="g-3 mb-3">
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="password">Password</Form.Label>
              <div className="form-icon-container">
                <Form.Control
                  id="password"
                  type="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                  className="form-icon-input"
                />
                <FontAwesomeIcon icon={faLock as IconProp} className="form-icon" />
              </div>
            </Form.Group>
          </Col>
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="confirmPassword">
                Confirm Password
              </Form.Label>
              <div className="form-icon-container">
                <Form.Control
                  id="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  required
                  className="form-icon-input"
                />
                <FontAwesomeIcon icon={faLock as IconProp} className="form-icon" />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Form.Check type="checkbox" className="mb-3">
          <Form.Check.Input
            type="checkbox"
            name="termsService"
            id="termsService"
            onChange={handleCheckboxChange}
            required
          />
          <Form.Check.Label
            htmlFor="termsService"
            className="fs-9 text-transform-none"
          >
            I accept the <Link to="#!">terms </Link>and{' '}
            <Link to="#!">privacy policy</Link>
          </Form.Check.Label>
        </Form.Check>
        <Button 
          variant="primary" 
          type="submit" 
          className="w-100 mb-3" 
          disabled={isLoading}
        >
          {isLoading ? 'Signing Up...' : 'Sign Up'}
        </Button>
        <div className="text-center">
          <Link
            to={`/pages/authentication/${layout}/sign-in`}
            className="fs-9 fw-bold"
          >
            Sign in to an existing account
          </Link>
        </div>
      </Form>

      <Modal show={showSuccessModal || showErrorModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{showSuccessModal ? 'Success' : 'Error'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SignUpForm;