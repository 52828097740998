import { FileAttachment } from 'components/common/AttachmentPreview';
import { supportChat, Message as MessageType, Conversation } from 'data/chat';
import dayjs from 'dayjs';
import { UserService } from 'helpers/userService';
import {
  createContext,
  PropsWithChildren,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useCallback
} from 'react';

interface ChatWidgetProps {
  isOpenChat: boolean;
  conversation: Conversation;
  setConversation: Dispatch<SetStateAction<Conversation>>;
  setIsOpenChat: Dispatch<SetStateAction<boolean>>;
  sentMessage: ({
    message,
    attachments
  }: {
    message?: string;
    attachments?: { images?: string[]; file?: FileAttachment };
  }) => void;
}

export const ChatWidgetContext = createContext({} as ChatWidgetProps);

const ChatWidgetProvider = ({ children }: PropsWithChildren) => {
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [conversation, setConversation] = useState(supportChat);

  const sentMessage = useCallback(
    async ({
      message,
      attachments
    }: {
      message?: string;
      attachments?: { images?: string[]; file?: FileAttachment };
    }) => {
      const newMessages = [
        ...conversation.messages,
        {
          id: Date.now(),
          type: 'sent', // 'received' if it is a chat response
          time: dayjs().toNow(),
          readAt: null,
          message,
          attachments
        } as MessageType
      ];
      const newConversation = { ...conversation, messages: newMessages };
      setConversation(newConversation);

      if (!message) return;

      try {
        // Get response from backend bot
        const botResponse = await UserService.getPromptResponse(message, 'general');

        // Add bot's response to the conversation
        const newBotMessage: MessageType = {
          id: Date.now() + 1,
          type: 'received',
          time: dayjs().toNow(),
          readAt: null,
          message: botResponse,
        };

        setConversation(prevConversation => ({
          ...prevConversation,
          messages: [...prevConversation.messages, newBotMessage]
        }));
      } catch (error) {
        console.error('Error getting bot response:', error);
        
        const offlineMessage: MessageType = {
          id: Date.now() + 1,
          type: 'received',
          time: dayjs().toNow(),
          readAt: null,
          message: "I'm sorry, but it seems I'm offline at the moment. Please try again later or contact our support team for assistance.",
        };

        setConversation(prevConversation => ({
          ...prevConversation,
          messages: [...prevConversation.messages, offlineMessage]
        }));
      }
    },
    [conversation]
  );

  return (
    <ChatWidgetContext.Provider
      value={{
        conversation,
        setConversation,
        isOpenChat,
        setIsOpenChat,
        sentMessage
      }}
    >
      {children}
    </ChatWidgetContext.Provider>
  );
};

export const useChatWidgetContext = () => useContext(ChatWidgetContext);

export default ChatWidgetProvider;
