import Logo from 'components/common/Logo';
import { Modal, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import SearchBox from 'components/common/SearchBox';
import Button from 'components/base/Button';
import ThemeToggler from 'components/common/ThemeToggler';
import classNames from 'classnames';
import { Children, useEffect, useRef, useState } from 'react';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';
import { hasSession } from 'helpers/utils';
import  Avatar from 'components/base/Avatar';
import avatar from 'assets/img/team/40x40/avatar1.png';
import { BotService } from 'helpers/botService';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

// Define the correct type for the Avatar status
type AvatarStatus = 'online' | 'offline' | 'do-not-disturb' | 'away';

const NavItem = ({
  label,
  url,
  isLast
}: {
  label: string;
  url: string;
  isLast?: boolean;
}) => {
  return (
    <Nav.Item
      as="li"
      className={classNames({
        'border-bottom border-translucent border-bottom-lg-0': !isLast
      })}
    >
      <Nav.Link href={url} className="lh-1 py-0 fs-9 fw-bold py-3">
        {label}
      </Nav.Link>
    </Nav.Item>
  );
};

const DefaultLandingNavbar = ({ className }: { className?: string }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [botStatus, setBotStatus] = useState<AvatarStatus>('do-not-disturb');

    const fetchBotStatus = async () => {
      const status = await BotService.getStatus();
      setBotStatus(status.status === 'Available' ? 'online' : 'do-not-disturb');
    };
  
    fetchBotStatus();
    const intervalId = setInterval(fetchBotStatus, 30000);

  useEffect(() => {
    const toggleShadowClass = () => {
      if (window.scrollY > 300) {
        containerRef.current?.classList.add('navbar-shadow');
      } else {
        containerRef.current?.classList.remove('navbar-shadow');
      }
    };

    document.addEventListener('scroll', () => toggleShadowClass());
    clearInterval(intervalId);
    return () => document.removeEventListener('scroll', toggleShadowClass);
  }, []);


  return (
    <>
      <div
        className={classNames(
          className,
          'bg-body-emphasis sticky-top landing-navbar'
        )}
        ref={containerRef}
      >
        <Navbar className="px-3 px-lg-7 px-xxl-3 container-small" expand="lg">
          <Navbar.Brand
            as={Link}
            to=""
            className="text-decoration-none flex-1 flex-lg-grow-0"
          >
            <Logo />
          </Navbar.Brand>
          <ThemeToggler className="mx-2 d-lg-none" />
         <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <div className="border-bottom border-translucent d-lg-none mb-2">
              <SearchBox
                placeholder="Search"
                className="w-100"
                inputClassName="rounded-pill my-4"
              />
            </div>
            <Nav className="me-auto mb-10 mb-lg-0" as="ul">
       
              <NavItem label="Hazbot" url="#home" />
              <NavItem label="Features" url="#features" />
              <NavItem label="Enterprise Solutions" url="#enterprise" />
              <NavItem label="Pricing" url="#pricing" />
              <NavItem label="Partners" url="#partners" />
              <NavItem label="What's New" url="#whatsnew" />
              <NavItem label="Get in Touch" url="#support" isLast />
            </Nav>
          
            <div className="d-grid d-lg-flex gap-4 align-items-center">
          {/*    <ThemeToggler className="d-none d-lg-block" />
              <Button
                className="p-0 text-body-tertiary text-body-emphasis-hover d-none d-lg-inline lh-sm"
                onClick={() => setOpenSearchModal(!openSearchModal)}
              >
                <FeatherIcon icon="search" size={20} />
              </Button>*/}

              { /* Conditional rendering based on session status */ }
              {hasSession() ? (
                  <div className="mb-0 text-body-tertiary" style={{position: "fixed", bottom: 10, right: 10}}>
                <Link
                  to="/hazbot"
                  className="btn btn-phoenix-primary"
                  title={botStatus === 'online'
                    ? "I'm online and ready to assist you!"
                    : "I'm currently offline. Please check back later."}
                >
                  <Avatar
                    src={avatar}
                    size="3xl"
                    status={botStatus}
                    className="mx-auto"
                  /> Back to Hazbot
                </Link></div>
              ) : (
                <>
                  <Link
                    to="/pages/authentication/split/Sign-In"
                    className="btn btn-link p-0 text-body order-1 order-lg-0"
                  >
                    Sign in
                  </Link>
                  <Link
                    to="/pages/authentication/split/Sign-Up"
                    className="btn btn-phoenix-primary order-0"
                  >
                    Sign up
                  </Link>
                </>
              )}
            </div>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15"
      >
        <Modal.Body className="p-0 bg-transparent">
          <DropdownSearchBox
            size="lg"
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            style={{ width: 'auto' }}
          >
            <SearchResult />
          </DropdownSearchBox>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DefaultLandingNavbar;
