import { Col, Row } from 'react-bootstrap';
import Button from 'components/base/Button';
import { Link } from 'react-router-dom';
import bg1 from 'assets/img/bg/bg-36.png';
import bg2 from 'assets/img/bg/fire.jpg';
import bg3 from 'assets/img/bg/fire.jpg';
import bg4 from 'assets/img/bg/bg-39.png';
import phoneImage from 'assets/img/bg/phone.png'; // Import the new phone image

const HeroHeader = () => {
  return (
<section id="home" className="pb-0 overflow-block" style={{ overflowX: 'hidden', overflowY: 'hidden', maxHeight: '100vh' }}>
  <div className="hero-header-container-alternate position-relative">
    <div className="container-small px-lg-7 px-xxl-3">
      <Row className="align-items-center">
        <Col lg={6} className="pt-4 pb-6 position-relative z-5 text-center text-lg-start">
          <h1 className="fs-3 fs-md-2 fs-xl-1 fw-black mb-4">
            <span className="text-gradient-info me-3">HAZBOT</span>
            <br />
            HazMat powered AI
          </h1>
          <p className="mb-4 pe-xl-10">
            Built by hazardous materials experts using the latest in AI technology.
          </p>
          <Button as={Link} to="../../pages/authentication/split/Sign-Up" variant="primary" size="lg" className="rounded-pill me-3">
            Try It Now!
          </Button>
        </Col>
        <Col lg="auto" className="d-none d-lg-block">
          <div className="hero-image-container position-absolute h-100 end-0 d-flex align-items-center">
            <div className="position-relative">
              <div className="position-absolute end-0 hero-image-container-overlay" />
              <img src={bg1} alt="" className="position-absolute end-0 hero-image-container-bg" />
              <img src={bg2} alt="" className="w-100 d-dark-none rounded-2 hero-image-shadow" />
              <img src={bg3} alt="" className="w-100 d-light-none rounded-2 hero-image-shadow" />
              <img
                src={phoneImage}
                alt="Phone"
                className="position-absolute"
                style={{
                  maxWidth: '60%',
                  maxHeight: 'auto', // Prevent height overflow
                  top: '-40%',
                  left: '-25%',
                  zIndex: 5
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
    {/* Mobile version */}
    <div className="container-small px-md-8 mb-12 d-lg-none">
      <div className="position-relative">
        <div className="position-absolute end-0 hero-image-container-overlay" />
        <img src={bg4} alt="" className="position-absolute top-50 hero-image-container-bg" />
        <img src={bg2} alt="" className="img-fluid ms-auto d-dark-none rounded-2 hero-image-shadow" />
        <img src={bg3} alt="" className="img-fluid ms-auto d-light-none rounded-2 hero-image-shadow" />
        <img
  src={phoneImage}
  alt="Phone"
  className="position-absolute"
  style={{
    width: '60%', // Increase the width
    maxWidth: '80%',
    top: '-15%',
    left: '-15%', // Adjust for better positioning
    zIndex: 5
  }}
/>

      </div>
    </div>
  </div>
</section>

  );
};

export default HeroHeader;
