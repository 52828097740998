import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HazbotConversation from 'components/common/chat-widget/HazbotConversation';
import HazbotFooter from 'components/common/chat-widget/HazbotFooter';
//import HazbotSkills from 'components/common/chat-widget/HazbotSkills';
//import HazbotToolbox from 'components/common/chat-widget/HazbotToolbox';
import { useAppContext } from 'providers/AppProvider';
import { useChatWidgetContext } from 'providers/ChatWidgetProvider';
import { faCircle, faDiceD20, faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { BotService } from 'helpers/botService';
/*
import 'components/react-ui-select-icon/SelectIcon.css'
import SelectIcon from 'components/react-ui-select-icon/SelectIcon';
const images = importAll(require.context('assets/img/select-icons/', false, /\.(png|jpe?g|svg)$/));


function importAll(r) {
  const images = {};
  r.keys().forEach((key) => {
    images[key] = r(key);
  });
  
  // Return the images object
  return images;
}*/

const Ecommerce = () => {
  const {
    config: { isChatWidgetVisible }
  } = useAppContext();
  const { isOpenChat, setIsOpenChat } = useChatWidgetContext();
  const [botStatus, setBotStatus] = useState({ status: "Unavailable", cssClass: "text-danger" });

  useEffect(() => {
    const fetchBotStatus = async () => {
      const status = await BotService.getStatus();
      setBotStatus(status);
    };

    fetchBotStatus();
    // Set up an interval to check status every 30 seconds
    const intervalId = setInterval(fetchBotStatus, 30000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  /*const [icons, setIcons] = useState([
    { id: "1", iconFile: images['./cat1.png'], title:"Tree" },
    { id: "2", iconFile: images['./cat2.png'], title: "" },
    { id: "3", iconFile: images['./cat3.png'], title: "Paris" },
    { id: "4", iconFile: images['./cat4.png'], title: "" },
    { id: "5", iconFile: images['./cat5.png'], title: "" },
    { id: "6", iconFile: images['./cat6.png'], title: "" },
    { id: "7", iconFile: images['./cat7.png'], title: "" },
    { id: "8", iconFile: images['./cat8.png'], title: "Flower" },
    { id: "9", iconFile: images['./cat9.png'], title: "" },
    { id: "10", iconFile: images['./cat10.png'], title: "" },
    { id: "11", iconFile: images['./cat11.png'], title: "Egg" },
    { id: "12", iconFile: images['./cat12.png'], title: "" },
    { id: "13", iconFile: images['./cat13.png'], title: "Pink" },
  ]);
  const [selectedIconId, setSelectedIconId] = useState("11");

  const handleIconSelection = (id) => {
    console.log(id);
  } */
  return (
    <>
      <Row className="g-2 mb-2">
        <Col xs={5} xxl={5} xg={5} xl={5}>
          <h6 className="mb-0 d-flex align-items-center gap-2">Neuroskills</h6>
        </Col>

        {/*<div className="App" style={{display:'flex', height:'100vh', justifyContent: 'center', alignItems: 'center'}}>
      <SelectIcon
        icons={icons}
        selectedId={selectedIconId}
        onSelect={handleIconSelection}
        containerWidth="100px"
        containerHeight="90px"
        selectedIconSize="64px"
        itemSize="90px"
        itemIconSize="64px"
        itemsPerRow={4}
        isAnimated={true}
        overlayColor='rgba(0, 0, 0, 0.1)'
        //containerStyle={{ position:'absolute', left:'20px', top: '20px' }}
        //containerClassName=''
      />
    </div>*/}

        <Col xs={7} xxl={7} xg={7} xl={7}>
          <FontAwesomeIcon icon={faDiceD20} />
          <FontAwesomeIcon icon={faDownload} />
          <FontAwesomeIcon icon={faUpload} />
          <h5 className="mb-0 d-flex align-items-center gap-2">Standard Abilities</h5>
        </Col>
      </Row>

      <Row className="g-1">
        <Col xs={12} xxl={12} xg={12} xl={12}>
          <Card className="bg-body-emphasis chat">
            <Card.Header className="d-flex flex-between-center px-4 py-2 border-bottom">
              <h6 className="mb-0 d-flex align-items-center gap-2">
                Hazbot is {botStatus.status}
                <FontAwesomeIcon icon={faCircle} className={`${botStatus.cssClass} fs-11`} />
              </h6>
            </Card.Header>
            <Card.Body className="scrollbar p-3">
              <HazbotConversation />
            </Card.Body>
            <Card.Footer className="border-top ps-3 pe-4 py-3">
              <HazbotFooter />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Ecommerce;